















































































import Vue from 'vue';
import { links } from '@/utils/link-detector';
import QRLinks from '@/components/Mobile/QRLinks.vue';
import ExpandableText from '@/components/ExpandableText.vue';

export default Vue.extend({
  components: {
    QRLinks,
    ExpandableText,
  },
  mounted() {
    this.internalBio = this.bio;
  },
  props: {
    bio: {
      type: String,
      required: true,
      default: '',
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      internalBio: '',
    };
  },
  methods: {
    bioLinks() {
      return links(this.internalBio, [], []);
    },
    beginEditing() {
      this.isEditing = true;
    },
    endEditing() {
      this.isEditing = false;
    },
    submitForm() {
      this.endEditing();
      if (this.internalBio != this.bio) {
        this.$emit('done', this.internalBio);
      }
    },
  },
});
