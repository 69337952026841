




























































































































import Vue from 'vue';

export default Vue.extend({
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    beginEditing() {
      this.isEditing = true;
    },
    commitEditing() {
      this.isEditing = false;
      this.$emit('commit');
    },
    discardEditing() {
      this.isEditing = false;
      this.$emit('discard');
    },
    onFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        this.$emit('select', files[0]);
      }
    },
    onDelete() {
      this.$emit('delete');
    },
  },
});
