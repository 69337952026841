



























import CancelButton from '@/components/Mobile/Button/CancelButton.vue';
import DoneButton from '@/components/Mobile/Button/DoneButton.vue';
import EditButton from '@/components/Mobile/Button/EditButton.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { EditButton, CancelButton, DoneButton },
  props: {
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      internalName: '',
    };
  },
  mounted() {
    this.internalName = this.name;
  },
  methods: {
    beginEditing() {
      this.isEditing = true;
    },
    endEditing() {
      this.isEditing = false;
    },
    saveName() {
      this.endEditing();
      this.$store.dispatch('auth/setDisplayName', this.internalName);
    },
  },
});
