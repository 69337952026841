














































import Vue from 'vue';
import { Message } from 'vue-advanced-chat';
import { mapGetters } from 'vuex';
import { UIMessage, uiMessageFromChatMessage } from '@/chat/chat.ui.message';

const dummyMessage = (text: string): Message => {
  return {
    content: text,
    _id: '',
    senderId: '',
    date: '',
    timestamp: '',
    reactions: {},
  };
};

export default Vue.extend({
  props: {
    clubId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      messages: 'clubchat/getMessages',
    }),
    recentMessages(): UIMessage[] {
      const recents = this.messages.slice(-2);
      const count = recents.length;
      if (!count)
        return [dummyMessage(this.$t('club.chat.empty.message') as string)];
      return recents.map(uiMessageFromChatMessage);
    },
  },
  mounted() {
    this.$store.dispatch('clubchat/bindMessages', { clubId: this.clubId });
  },
  beforeDestroy() {
    this.$store.dispatch('clubchat/unbindMessages');
  },
});
