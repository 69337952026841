



































































































import Vue from 'vue';
export default Vue.extend({
  data() {
    return {};
  },
  props: {
    userStats: {
      type: Object,
      required: true,
    },
    followingCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    computedKarmapoints() {
      if (this.userStats) {
        let offeredToursCount = 0,
          doneToursCount = 0;
        if (this.userStats['offeredToursCount']) {
          offeredToursCount = this.userStats['offeredToursCount'];
        }
        if (this.userStats['doneToursCount']) {
          doneToursCount = this.userStats['doneToursCount'];
        }
        return Math.abs(offeredToursCount) * 5 + Math.abs(doneToursCount) * 3;
      }
      return 0;
    },
  },
});
