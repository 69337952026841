
















































import Vue from 'vue';
import Place from '@/dtos/place';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import DoneButton from '@/components/Mobile/Button/DoneButton.vue';
import CancelButton from '@/components/Mobile/Button/CancelButton.vue';
import EditButton from '@/components/Mobile/Button/EditButton.vue';

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
    DoneButton,
    CancelButton,
    EditButton,
  },
  props: {
    location: {
      type: Place,
      required: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedLocation: undefined as any | undefined,
      selectedDescription: undefined as string | undefined,
      isEditing: false,
    };
  },
  mounted() {
    this.setSelectedLocation();
  },
  methods: {
    setSelectedLocation() {
      if (this.location) {
        this.selectedLocation = {
          latitude: this.location.latitude,
          longitude: this.location.longitude,
        };
        this.selectedDescription = this.location.description;
      }
    },
    beginEditing() {
      this.isEditing = true;
    },
    cancel() {
      this.endEditing();
      this.setSelectedLocation();
    },
    endEditing() {
      this.isEditing = false;
    },
    onLocationSelect(l: any) {
      this.selectedLocation = l;
    },
    onLocationInput(value: string) {
      this.selectedDescription = value;
      if (!value.includes(this.selectedLocation.locality)) {
        this.selectedLocation = undefined;
      }
    },
    saveLocation() {
      this.endEditing();
      let location: Place | null = null;
      if (this.selectedDescription) {
        location = new Place(
          this.selectedLocation?.latitude || 0,
          this.selectedLocation?.longitude || 0,
          this.selectedDescription
        );
      }
      this.$store.dispatch('auth/updateUserDetails', { location: location });
    },
  },
});
