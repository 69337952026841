



















import {
  ImageType,
  processProfileImage,
} from '@/store/modules/user.profile.picture';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ImageControls from './ImageControls.vue';

export default Vue.extend({
  components: { ImageControls },
  props: {
    imageURL: {
      type: String,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    this.hasImage = this.hasImageURL;
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    hasImageURL(): boolean {
      return this.imageURL != null && this.imageURL.length > 0;
    },
  },
  data() {
    return {
      isLoading: false,
      file: undefined as File | undefined,
      fileURL: null as string | null,
      hasImage: false,
    };
  },
  methods: {
    onCommit() {
      this.isLoading = true;
      processProfileImage({
        userId: this.user.data.uid,
        type: ImageType.hero,
        file: this.file,
        delete: !this.hasImage && this.hasImageURL,
      })?.then(() => (this.isLoading = false));
    },
    onDiscard() {
      this.file = undefined;
      this.fileURL = null;
      this.hasImage = this.hasImageURL;
    },
    onSelect(file: File) {
      this.file = file;
      this.fileURL = URL.createObjectURL(file);
      this.hasImage = true;
    },
    onDelete() {
      this.hasImage = false;
    },
  },
});
