
























const tabs = [
  { name: 'Vergangene', id: 0 },
  { name: 'Erstellte', id: 1 },
  { name: 'Zukünftige', id: 2 },
];

import Vue from 'vue';
import FullwidthTabs from '../FullwidthTabs.vue';
import TourCard from '@/components/Mobile/TourCard.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { FullwidthTabs, TourCard },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs,
      currentTabId: 2,
      defaultTab: 2,
    };
  },
  computed: {
    ...mapGetters({
      userPastTours: 'tour/getUserPastTours',
      userCreatedTours: 'tour/getUserCreatedTours',
      userFutureTours: 'tour/getUserFutureTours',
    }),
    computedTours(): any {
      if (this.currentTabId === 0) {
        return this.userPastTours(this.uid);
      } else if (this.currentTabId === 1) {
        return this.userCreatedTours(this.uid);
      }
      return this.userFutureTours(this.uid);
    },
  },
  mounted() {
    const dispatch = this.$store.dispatch;
    Promise.all([
      dispatch('tour/bindToursRef'),
      dispatch('tour/bindPastToursRef'),
    ])
      .then(() => [
        this.userPastTours(this.uid).length,
        this.userCreatedTours(this.uid).length,
        this.userFutureTours(this.uid).length,
      ])
      .then(
        (counts) =>
          (this.defaultTab = counts.reduce((indexToSelect, _, i, arr) =>
            arr[i] > 0 ? i : indexToSelect
          ))
      )
      .then(() => this.tabSelected(this.defaultTab));
  },
  methods: {
    tabSelected(tabId: number): void {
      this.currentTabId = tabId;
    },
  },
});
