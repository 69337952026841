








































import Vue from 'vue';
import ProfileHeroImage from '@/components/Mobile/Profile/ProfileHeroImage.vue';
import ProfileImage from '@/components/Mobile/Profile/ProfileImage.vue';
import UserLocationField from '@/user/UserLocationField.vue';
import Place from '@/dtos/place';
import UserNameField from '@/user/UserNameField.vue';

export default Vue.extend({
  components: {
    ProfileHeroImage,
    ProfileImage,
    UserLocationField,
    UserNameField,
  },
  props: {
    username: {
      type: String,
      required: true,
      default: null,
    },
    name: {
      type: String,
      required: true,
      default: null,
    },
    profileImageURL: {
      type: String,
      required: false,
      default: null,
    },
    heroImageURL: {
      type: String,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClub: {
      type: Boolean,
      required: false,
      default: false,
    },
    location: {
      type: Place,
      required: false,
      default: undefined,
    },
  },
  methods: {
    onClubChecked(event: Event) {
      const input = event.target as HTMLInputElement;
      this.$store.dispatch('user/markAsClub', input.checked);
    },
  },
});
